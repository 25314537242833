const TimerCollection = {};

let pauseTime = undefined;
let pausedTime = 0;
export const getPausedTime = () => pausedTime;
export const clearPausedTime = () => pausedTime = 0;
export const pause = () => {
  pauseTime = Date.now();
  Object.keys(TimerCollection).forEach((key) => TimerCollection[key].pause());
};
export const resume = () => {
  pausedTime += Date.now() - pauseTime;
  Object.keys(TimerCollection).forEach((key) => TimerCollection[key].resume());
};

export class TimeoutTimer {
  constructor(callback, delay) {
    this.startTime = Date.now();
    this.callback = callback;
    this.time = delay;
    this.id = undefined;

    this.start();
  }

  start() {
    this.id = setTimeout(this.callback, this.time);
    TimerCollection[this.id] = this;
  }

  pause() {
    this.clear();
    this.time -= Date.now() - this.startTime;
  }

  resume() {
    this.startTime = Date.now();
    this.destroy();
    this.start();
  }

  clear() {
    clearTimeout(this.id);
  }

  destroy() {
    this.clear();
    delete TimerCollection[this.id];
  }
}

export class IntervalTimer {
  constructor(callback, delay) {
    this.callback = callback;
    this.time = delay;
    this.id = undefined;

    this.start();
  }

  start() {
    this.id = setInterval(this.callback, this.time);
    TimerCollection[this.id] = this;
  }

  pause() {
    this.clear();
  }

  resume() {
    this.destroy();
    this.start();
  }

  clear() {
    clearInterval(this.id);
  }

  destroy() {
    this.clear();
    delete TimerCollection[this.id];
  }
}
