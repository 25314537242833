import { Game } from '@/enums';

export default class Enemy {
  constructor({ context, width, height, state, background, player, onCrash, speed, data }) {
    this.context = context;
    this.canvasWidth = width;
    this.canvasHeight = height;
    this.state = state;
    this.background = background;
    this.player = player;
    this.onCrash = onCrash;
    this.speed = speed;
    this.data = data;
    this.rotation = this.data.rotate;
    this.imageIndex = 0;

    this.create();
  }

  create() {
    const { height: backgroundHeight } = this.background.data;
    const images = Array.isArray(this.data.src) ? this.data.src : [this.data.src];

    this.data.y = this.data.y * this.canvasHeight / backgroundHeight;
    this.images = images.map((src) => {
      const image = new Image();
      image.src = src;

      return image;
    });
  }

  render({ state }) {
    this.state = state;

    if (!this.lastImageSwithTimestamp) {
      this.lastImageSwithTimestamp = Date.now();
    }

    const now = Date.now();
    if (now - this.lastImageSwithTimestamp >= 100) {
      this.lastImageSwithTimestamp = Date.now();
      this.imageIndex += 1;

      if (this.imageIndex > this.images.length - 1) {
        this.imageIndex = 0;
      }
    }

    const image = this.images[this.imageIndex];
    const { width: imageWidth, height: imageHeight } = image;

    if (!imageWidth || !imageHeight) {
      return;
    }

    const height = (this.data.multiplier || Game.PLAYER_AND_ENEMY_MULTIPLIER) * this.canvasHeight;
    const aspect = imageWidth / imageHeight;
    const width = height * aspect;

    Object.assign(this.data, { image, width, height });

    if (this.state.isPlaying) {
      this.data.x -= this.speed;
    }

    this.draw();
  }

  draw() {
    if (!isNaN(this.data.rotate)) {
      return this.drawRotated();
    }

    this.context.drawImage(
      this.data.image,
      this.data.x,
      this.data.y,
      this.data.width,
      this.data.height,
    );
  }

  drawRotated() {
    this.context.save();

    const canvas = document.createElement('canvas');
    const widthWithMultiplier = this.data.width * Game.ENEMY_ROTATE_SIZE_MULTIPLIER;
    const heightWithMultiplier = this.data.width * Game.ENEMY_ROTATE_SIZE_MULTIPLIER;
    canvas.width = this.data.width + widthWithMultiplier;
    canvas.height = this.data.height + heightWithMultiplier;
    const context = canvas.getContext('2d');
    context.save();
    context.translate(this.data.width / 2 + widthWithMultiplier / 2, this.data.height / 2 + heightWithMultiplier / 2);
    context.rotate(this.data.rotate * Math.PI / 180);
    context.translate(-(this.data.width / 2 + widthWithMultiplier / 2), -(this.data.height / 2 + heightWithMultiplier / 2));
    context.drawImage(
      this.data.image,
      widthWithMultiplier / 2,
      heightWithMultiplier / 2,
      this.data.width,
      this.data.height,
    );
    context.restore();

    this.context.drawImage(
      canvas,
      this.data.x - widthWithMultiplier / 2,
      this.data.y - heightWithMultiplier / 2,
      this.data.width + widthWithMultiplier,
      this.data.height + heightWithMultiplier,
    );

    this.data.rotate += this.rotation;
  }

  destroy() {
    this.data = {};
  }
}
