import { Game } from '@/enums';

const IMAGES = [
  '/cat.png',
  '/cat.png',
  '/cat.png',
  '/cat_green_light.png',
  '/cat.png',
  '/cat.png',
  '/cat.png',
  '/cat_green_light.png',
  '/cat.png',
  '/cat.png',
  '/cat.png',
  '/cat_green_light.png',
  '/cat_half_closed_eyes.png',
  '/cat_closed_eyes.png',
  '/cat_half_closed_eyes.png',
  '/cat_green_light.png',
];

export default class Airship {
  constructor({ context, width, height }) {
    this.context = context;
    this.canvasWidth = width;
    this.canvasHeight = height;
    this.data = {};
    this.rotation = 25; // deg

    this.create();
  }

  create() {
    this.x = 0;
    this.y = 0;
    this.width = Game.PLAYER_AND_ENEMY_MULTIPLIER * this.canvasHeight;
    this.height = this.width;
    this.imageIndex = 0;
    this.images = IMAGES.map((src) => {
      const image = new Image();
      image.src = `${Game.PREFIXES.images}${src}`;

      return image;
    });
  }

  render({ x, y }) {
    if (!this.lastImageSwithTimestamp) {
      this.lastImageSwithTimestamp = Date.now();
    }

    const now = Date.now();
    if (now - this.lastImageSwithTimestamp >= 100) {
      this.lastImageSwithTimestamp = Date.now();
      this.imageIndex += 1;

      if (this.imageIndex > this.images.length - 1) {
        this.imageIndex = 0;
      }
    }

    const image = this.images[this.imageIndex];
    const { width: imageWidth, height: imageHeight } = image;
    const aspect =  imageHeight / imageWidth;

    this.data = { ...this.data, image, x, y, width: this.width, height: this.width * aspect };
  }

  draw(hasCollided) {
    if (hasCollided) {
      return this.drawRotated();
    }

    this.context.drawImage(
      this.data.image,
      this.data.x,
      this.data.y,
      this.data.width,
      this.data.height,
    );
  }

  drawRotated() {
    this.data.rotate = this.data.rotate || this.rotation;
    this.context.save();

    const canvas = document.createElement('canvas');
    const widthWithMultiplier = this.data.width * Game.PLAYER_ROTATE_SIZE_MULTIPLIER;
    const heightWithMultiplier = this.data.width * Game.PLAYER_ROTATE_SIZE_MULTIPLIER;
    canvas.width = this.data.width + widthWithMultiplier;
    canvas.height = this.data.height + heightWithMultiplier;
    const context = canvas.getContext('2d');
    context.save();
    context.translate(this.data.width / 2 + widthWithMultiplier / 2, this.data.height / 2 + heightWithMultiplier / 2);
    context.rotate(this.data.rotate * Math.PI / 180);
    context.translate(-(this.data.width / 2 + widthWithMultiplier / 2), -(this.data.height / 2 + heightWithMultiplier / 2));
    context.drawImage(
      this.data.image,
      widthWithMultiplier / 2,
      heightWithMultiplier / 2,
      this.data.width,
      this.data.height,
    );
    context.restore();

    this.context.drawImage(
      canvas,
      this.data.x - widthWithMultiplier / 2,
      this.data.y - heightWithMultiplier / 2,
      this.data.width + widthWithMultiplier,
      this.data.height + heightWithMultiplier,
    );

    this.data.rotate += this.rotation;
  }
}
