import { Game } from '@/enums';
import Airship from './airship';
import Engine from './engine';

const BASE_VELOCITY = 2;
const JUMP_VELOCITY = -6;
const ROTATION_DEGREES = 5;

export default class Player {
  constructor({ context, width, height, state, onCrash }) {
    this.context = context;
    this.canvasWidth = width;
    this.canvasHeight = height;
    this.state = state;
    this.onCrash = onCrash;
    this.hasCollided = false;

    this.create();
  }

  create() {
    this.x = 0.1 * this.canvasWidth;
    this.y = 0;
    this.fallingCounter = new Date();
    this.velocityAmount = 0.002 * this.canvasHeight;
    this.velocity = BASE_VELOCITY * this.velocityAmount;
    this.force = 0.15 * this.velocityAmount;

    this.airship = new Airship({ context: this.context, width: this.canvasWidth, height: this.canvasHeight });
    this.engine = new Engine({ context: this.context, width: this.canvasWidth, height: this.canvasHeight, airship: this.airship });
  }

  render() {
    this.draw();

    if (this.state.isPlaying) {
      this.fall();
    }
  }

  draw() {
    if (!this.hasCollided) {
      this.context.save();
      this.context.rotate(ROTATION_DEGREES * Math.PI / 180);
    }

    this.airship.render({ x: this.x, y: this.y });
    this.engine.draw(this.hasCollided);
    this.airship.draw(this.hasCollided);

    if (!this.hasCollided) {
      this.context.restore();
    }
  }

  pause() {
    this.velocity = BASE_VELOCITY * this.velocityAmount;
  }

  reset() {
    this.pause();
    this.y = 0;

    this.draw();
  }

  jump() {
    if (!this.hasCollided) {
      this.velocity = this.velocity + JUMP_VELOCITY * this.velocityAmount;
    }
  }

  fall() {
    var now = Date.now();

    if (now - this.fallingCounter <= 1000 / Game.FPS) {
      return;
    }

    if (this.velocity < 8) {
      this.velocity += this.force;
    }

    this.y += this.velocity * (this.hasCollided ? 2 : 1);
    
    if (this.y < 0) {
      this.y = 0;
      this.velocity = 2 * this.velocityAmount;
    }

    if (this.y > this.canvasHeight) {
      this.onCrash();
      this.reset();
    }
  }

  setCollision(value) {
    this.hasCollided = value;
  }
}
