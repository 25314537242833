import { Game } from '@/enums';

const ENGINE_START_IMAGES = [
  '/engine_start1.png',
  '/engine_start2.png',
  '/engine_start3.png',
];

const ENGINE_IMAGES = [
  '/engine1.png',
  '/engine2.png',
  '/engine3.png',
  '/engine4.png',
];

export default class Engine {
  constructor({ context, width, height, airship }) {
    this.context = context;
    this.canvasWidth = width;
    this.canvasHeight = height;
    this.airship = airship;

    this.create();
  }

  create() {
    this.engineStartImages = ENGINE_START_IMAGES.map((source) => {
      const image = new Image();
      image.src = `${Game.PREFIXES.images}${source}`;

      return image;
    });

    this.engineImages = ENGINE_IMAGES.map((source) => {
      const image = new Image();
      image.src = `${Game.PREFIXES.images}${source}`;

      return image;
    });

    this.imageFrameIndex = 0;
  }

  draw(hasCollided) {
    if (hasCollided) {
      return;
    }

    if (!this.lastImageSwithTimestamp) {
      this.lastImageSwithTimestamp = Date.now();
    }

    const now = Date.now();
    if (now - this.lastImageSwithTimestamp >= 50) {
      this.lastImageSwithTimestamp = now;
      this.imageFrameIndex += 1; 
    }

    if (this.imageFrameIndex === ENGINE_START_IMAGES.length) {
      this.engineStarted = true;
      this.imageFrameIndex = 0;
    }

    const { x, y, width, height } = this.airship.data;
    const images = this.engineStarted ? this.engineImages : this.engineStartImages;
    const image = images[this.imageFrameIndex];
    const { width: imageWidth, height: imageHeight } = image;
    const aspect =  imageHeight / imageWidth;
    const engineWidth = width / 2;

    this.context.drawImage(
      image,
      0,
      0,
      imageWidth,
      imageHeight,
      x + engineWidth / 2,
      y + 0.75 * height,
      engineWidth,
      engineWidth * aspect,
    );

    // Note: draw trail image
    // this.context.drawImage(
    //   this.engineImage.trail,
    //   0,
    //   0,
    //   width,
    //   height,
    //   x + width / 4 - this.engineImage.trail.width / 4,
    //   y + height - height / 2,
    //   width / 2,
    //   height / 2,
    // );
  }
}