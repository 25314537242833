<template>
  <div
    v-show="isFootVisible"
     :style="{
      width: `${footStyle.width + 2 * Foot.BORDER_WIDTH}px`,
      height: `${footStyle.height + 2 * Foot.BORDER_WIDTH}px`,
      marginTop: `-${footStyle.height / 2 + 2 * Foot.BORDER_WIDTH}px`,
    }"
    class="ui-game__foot">
      <canvas ref="canvas" :width="footStyle.width" :height="footStyle.height"></canvas>
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue';
import { store } from '@/components/ui-game/store';
import { Foot, MoveType } from '@/enums';
import { addLeadingZero, compareValues, getIsValueEqualsTrue, hexToColor } from '@/utils';

const data = [
  "00:9:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "01:6:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "02:4:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "03:F:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "04:9:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "05:6:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "06:4:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "07:F:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "08:9:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "09:6:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "0A:4:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "0B:F:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "0C:9:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "0D:6:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "0E:4:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "0F:F:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "10:9:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "11:6:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "12:4:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "13:F:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "14:9:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "15:6:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "16:4:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "17:F:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "18:9:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "19:6:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "1A:4:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "1B:F:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "1C:9:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "1D:6:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "1E:4:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "1F:F:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
];

const props = defineProps({
  isRegisterMode: Boolean,
});

const canvas = ref(null);
const previousFootState = ref();

const isFootVisible = computed(() =>
  getIsValueEqualsTrue(store.state.footOnlyMode) ||
  (getIsValueEqualsTrue(store.state.showFootPrint) && (store.state.isPlaying || store.state.isPaused)));

const footStyle = computed(() => ({
  width: getIsValueEqualsTrue(store.state.footOnlyMode) ? Foot.FOOT_ONLY_WIDTH : Foot.FOOT_WIDTH,
  height: getIsValueEqualsTrue(store.state.footOnlyMode) ? Foot.FOOT_ONLY_HEIGHT : Foot.FOOT_HEIGHT,
}));

const parse = (value) => {
  const [row] = value.split(':');
  const rowInfo = addLeadingZero(row).toLowerCase();
  const currentRowIndex = data.findIndex((record) => addLeadingZero(record.split(':')[0]).toLowerCase() === rowInfo);

  if (currentRowIndex !== -1) {
    data.splice(currentRowIndex, 1, value);
  }

  render(data);
};

const parseHexString = (hex) => {
  const result = [];
  let i = 0;

  while (i < hex.length) {
    const current = hex[i];
    const next = hex[i + 1];

    if (current && !next) {
      result.push(parseInt(`${current}`, 16));
      i += 1;
    } else if (current === '0' && next === '0') {
      result.push(0);
      i += 1;
    } else if (current === '0' && next !== '0') {
      if (hex[i + 2] && hex[i + 2] !== '0') {
        result.push(0);
        i += 1;
      } else {
        result.push(parseInt(`${current}${next}`, 16));
        i += 2;
      }
    } else if(current !== '0') {
      result.push(parseInt(`${current}${next}`, 16));
      i += 2;
    }
  }

  return result;
}

const getValues = (str) => {
  if (str.length === 2 * Foot.COLUMNS) {
    return str.match(/.{1,2}/g);
  }

  if (str.length === Foot.COLUMNS) {
    return str.match(/.{1}/g);
  }

  return parseHexString(str);
};

const render = (data) => {
  if (!canvas.value) {
    return;
  }

  const ctx = canvas.value.getContext('2d');
  const CELL_SIZE = footStyle.value.width / Foot.COLUMNS;

  // Note: flip vertically
  ctx.scale(1, -1);
  ctx.translate(0, -ctx.canvas.height);

  data.forEach((record) => {
    const [rowInfo, , sensorValues] = record.split(':');
    const row = parseInt(rowInfo, 16);
    const str = sensorValues.replace(/\s/g, '');
    const values = getValues(str);

    for (let column = 0; column < Foot.COLUMNS; column++) {
      const sensorValue = values[column];

      ctx.fillStyle = hexToColor(sensorValue, store.state.footColorIntencity);
      ctx.fillRect(column * CELL_SIZE, row * CELL_SIZE, CELL_SIZE, CELL_SIZE);
    }
  });

  // Note: reset
  ctx.setTransform(1, 0, 0, 1, 0, 0);
};

onMounted(() => {
  render(data);

  window.btReceived = (value) => {
    if (props.isRegisterMode) {
      return;
    }

    if (store.data.move_type === MoveType.SINGLE && compareValues(value, previousFootState.value)) {
      return;
    }

    previousFootState.value = value;
    if (!Array.isArray(value)) {
      parse(value);
    } else {
      value.forEach(parse);
    }

    const [, key] = (Array.isArray(value) ? value[0] : value).split(':');
    window.keyPressed(key, true);
  };
});

defineExpose({ previousFootState });
</script>
