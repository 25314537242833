<template>
  <div class="ui-game__info-start">
    <img :src="startBackgroundSrc" class="ui-game__background" />
    <img :src="startCatSrc" class="ui-game__cat" />
    <img :src="`${Game.PREFIXES.images}/shadow.png`" class="ui-game__shadow" />
    <button class="ui-game__sound" @click.prevent="onSoundToggle">
      <img :src="soundSrc" />
    </button>
    <p @click.prevent="$emit(ActionType.START)" class="ui-game__info-text" v-text="'kliknij aby zagrać'"></p>
    <p v-if="bluetoothDeviceName" class="ui-game__bluetooth-device-name" v-text="bluetoothDeviceName"></p>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { store } from '@/components/ui-game/store';
import { ActionType, Game } from '@/enums';

const isLandscape = computed(() => window.innerWidth > window.innerHeight);
const soundSrc = computed(() => store.state.isSoundEnabled ? `${Game.PREFIXES.images}/sound_on.png` : `${Game.PREFIXES.images}/sound_off.png`);
const bluetoothDeviceName = store.state.bluetoothDeviceName;
const startBackgroundSrc = `${Game.PREFIXES.images}/${isLandscape ? 'start_background_horizontal' : 'start_background_vertical'}.jpg`;
const startCatSrc = `${Game.PREFIXES.images}/${isLandscape ? 'start_cat_horizontal' : 'start_cat_vertical'}.png`;

const onSoundToggle = () => store.state.isSoundEnabled = !store.state.isSoundEnabled;
</script>
