import { Game as GameEnum, EnemyType } from '@/enums';
import { TimeoutTimer, pause, resume } from '@/libs/timer';
import Background from './background';
import Player from './player';
import Enemies  from './enemies';
import Sound from './sound';

export default class Game {
  constructor({ $el, width, height, state, onUpdate }) {
    this.context = $el.getContext('2d', { willReadFrequently: true });
    this.width = width;
    this.height = height;
    this.state = state;
    this.onUpdate = onUpdate;
    this.previousTick = 0;
    this.requestId = undefined;
    this.sound = new Sound({ $el, state });
    this.timer = new TimeoutTimer(() => this.end(), this.state.level.time * 1000);

    this.start();
  }

  start() {
    this.props = { context: this.context, width: this.width, height: this.height, state: this.state };

    this.background = new Background({ ...this.props, onEnd: () => this.end() });
    this.player = new Player({ ...this.props, onCrash: () => this.crash() });
    this.sound.engine();

    this.update();
    this.play();
  }

  render() {
    if (this.background.data.width && !this.enemies) {
      this.enemies = new Enemies({
        ...this.props,
        background: this.background,
        player: this.player,
        speed: this.background.speed + 1,
        onCrash: (args) => this.crash(args),
        onScore: (args) => this.score(args),
      });
    }

    this.context.clearRect(0, 0, this.width, this.height);
    this.background.render({ state: this.state });

    if(this.enemies) {
      this.enemies.render({ state: this.state });
    }

    this.player.render({ state: this.state });
  }

  update() {
    this.requestId = window.requestAnimationFrame(() => this.update());

    const now = Math.round(GameEnum.FPS * Date.now() / 1000);
    if (now === this.previousTick) {
      return;
    }

    this.render();
    this.previousTick = now;
  }

  reset() {
    this.state.score = 0;

    this.background.reset();
    this.player.reset();

    this.play();
  }

  pause() {
    if (this.state.isPaused) {
      return;
    }

    pause();

    this.player.pause();
    this.state.isPlaying = false;
    this.state.isPaused = true;
    this.state.isEnded = false;

    this.onUpdate(this.state);
  }

  play() {
    if (this.state.isPlaying) {
      return;
    }

    resume();

    this.player.setCollision(false);
    this.state.isPaused = false;
    this.state.isPlaying = true;
    this.state.isEnded = false;

    this.onUpdate(this.state);
  }

  end() {
    if (this.state.isEnded) {
      return;
    }

    this.state.isEnded = true;
    this.state.isPaused = false;
    this.state.isPlaying = false;

    this.onUpdate(this.state);
  }

  click() {
    if (this.state.isPlaying) {
      return this.player.jump();
    }

    if (this.state.isPaused) {
      return this.play();
    }
  }

  async crash({ type } = {}) {
    this.sound.lost();

    if (type === EnemyType.DEFAULT && !this.state.isPaused) {
      return this.player.setCollision(true);
    }

    this.state.score = Math.max(0, this.state.score - this.state.killPenalty);
    this.player.setCollision(false);
    this.pause();
  }

  score({ type } = {}) {
    if (type === EnemyType.BONUS) {
      this.sound.bonus();
    }

    this.state.score++;
    this.onUpdate(this.state);
  }

  destroy() {
    this.background = null;
    this.player = null;
    this.enemies = null;
    this.timer.destroy();
    cancelAnimationFrame(this.requestId);
  }
}
