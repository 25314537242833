import { Game } from '@/enums';

export default (gameData) => {
  const baseImages = [
    `${Game.PREFIXES.images}/button_close.png`,
    `${Game.PREFIXES.images}/button_next.png`,
    `${Game.PREFIXES.images}/button_replay.png`,
    `${Game.PREFIXES.images}/cat.png`,
    `${Game.PREFIXES.images}/cat_closed_eyes.png`,
    `${Game.PREFIXES.images}/cat_green_light.png`,
    `${Game.PREFIXES.images}/cat_half_closed_eyes.png`,
    `${Game.PREFIXES.images}/end.png`,
    `${Game.PREFIXES.images}/engine_start1.png`,
    `${Game.PREFIXES.images}/engine_start2.png`,
    `${Game.PREFIXES.images}/engine_start3.png`,
    `${Game.PREFIXES.images}/engine1.png`,
    `${Game.PREFIXES.images}/engine2.png`,
    `${Game.PREFIXES.images}/engine3.png`,
    `${Game.PREFIXES.images}/engine4.png`,
    `${Game.PREFIXES.images}/gratz.png`,
    `${Game.PREFIXES.images}/menu_cat.png`,
    `${Game.PREFIXES.images}/menu_cat2.png`,
    `${Game.PREFIXES.images}/sound_off.png`,
    `${Game.PREFIXES.images}/sound_on.png`,
  ];

  const images = gameData.levels?.map((level) => {
    const { background, floor, enemies } = level;
    const backgrounds = Array.isArray(background) ? background : [background];
    const _images = [...backgrounds, floor];

    enemies.forEach(({ src }) => {
      if (Array.isArray(src)) {
        _images.push(...src);
      } else {
        _images.push(src);
      }
    });

    return _images;
  }) || [];

  [...baseImages, ...images.reduce((a, b) => a.concat(b), [])]
    .forEach((src) => {
      const image = new Image();
      image.src = src;
    });
};
