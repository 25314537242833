<template>
  <div class="ui-game__info-end">
    <img :src="`${Game.PREFIXES.images}/shadow.png`" class="ui-game__shadow" />
    <div class="ui-game__results">
      <div class="ui-game__results-action">
        <img :src="resultGratzImage" class="ui-game__results-gratz"/>
        <div class="ui-game__results-buttons">
          <a :href="restartLink" class="ui-game__results-link">
            <img :src="`${Game.PREFIXES.images}/button_close.png`" />
          </a>
          <button class="ui-game__results-button" @click.prevent="$emit(ActionType.REPLAY)">
            <img :src="`${Game.PREFIXES.images}/button_replay.png`" />
          </button>
          <button v-if="isNextLevelAvailable" class="ui-game__results-button" @click.prevent="$emit(ActionType.NEXT)">
            <img :src="`${Game.PREFIXES.images}/button_next.png`" />
          </button>
        </div>
      </div>
      <img :src="resultCatImage" class="ui-game__results-cat" />
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted } from 'vue';
import { ActionType, Game } from '@/enums';
import { store, getGameState } from '@/components/ui-game/store';

const isNextLevelAvailable = computed(() => store.data.levels[store.level + 1]);
const restartLink = computed(() => store.data.restart_link);
const resultCatImage = computed(() => `${Game.PREFIXES.images}/${isNextLevelAvailable.value ? 'menu_cat' : 'menu_cat2'}.png`);
const resultGratzImage = computed(() => `${Game.PREFIXES.images}/${isNextLevelAvailable.value ? 'gratz' : 'end'}.png`);

onMounted(() => {
  window.Flutter?.postMessage('postFlow', getGameState());
});
</script>
