<template>
  <div id="app-wrapper">
    <ui-game v-if="gameData" :data="gameData"/>
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import { UiGame } from '@/components';
import { Game } from '@/enums';
import { getFlowResult, preloadImages } from '@/utils';

const gameData = ref();

onMounted(async () => {
  gameData.value = window.GAME_DATA;

  if (gameData.value.imagesBasePath) {
    Game.PREFIXES.images = gameData.value.imagesBasePath;
  }

  if (gameData.value.soundsBasePath) {
    Game.PREFIXES.sound = gameData.value.soundsBasePath;
  }

  preloadImages(gameData.value);

  document.querySelector('.loader').style.display = 'none';

  window.flowResult = getFlowResult;
});
</script>
