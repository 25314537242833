import { getBase64Image } from '@/utils';

const DISTANCE_PERCENTAGE_MARGIN_INSET = 0.05;
let sourceCanvas;
let targetCanvas;

const calculatePrecisely = async (sourceData, targetData, canvasWidth, canvasHeight) => {
  if (!sourceCanvas) {
    sourceCanvas = document.createElement('canvas');
  }

  sourceCanvas.width = canvasWidth;
  sourceCanvas.height = canvasHeight;

  const sWidth = sourceData.width - 2 * DISTANCE_PERCENTAGE_MARGIN_INSET * sourceData.width;
  const sHeight = sourceData.height - 2 * DISTANCE_PERCENTAGE_MARGIN_INSET * sourceData.height;
  const sX = sourceData.x + DISTANCE_PERCENTAGE_MARGIN_INSET * sWidth;
  const sY = sourceData.y + DISTANCE_PERCENTAGE_MARGIN_INSET * sHeight;

  const sourceContext = sourceCanvas.getContext('2d', { willReadFrequently: true });
  const sourceImage = new Image();
  sourceImage.src = await getBase64Image(sourceData.image.src);
  sourceContext.drawImage(sourceImage, sX, sY, sWidth, sHeight);

  if (!targetCanvas) {
    targetCanvas = document.createElement('canvas');
  }

  targetCanvas.width = canvasWidth;
  targetCanvas.height = canvasHeight;

  const targetContext = targetCanvas.getContext('2d', { willReadFrequently: true });
  const targetImage = new Image();
  targetImage.src = await getBase64Image(targetData.image.src);
  targetContext.drawImage(targetImage, targetData.x, targetData.y, targetData.width, targetData.height);

  const cropX = (sX > targetData.x)
    ? [sX, (targetData.x + targetData.width) - sX + 1]
    : [targetData.x, (sX + sWidth) - targetData.x + 1];
  const cropY = (sY + sHeight > targetData.y + targetData.height)
    ? [sY, (targetData.y + targetData.height) - sY + 1]
    : [targetData.y, (sY + sHeight) - targetData.y + 1];

  if (cropX[1] <= 0 && cropX[1] > -1) {
    cropX[1] = -1;
  }

  if (cropX[1] >= 0 && cropX[1] < 1) {
    cropX[1] = 1;
  }

  if (cropY[1] <= 0 && cropY[1] > -1) {
    cropY[1] = -1;
  }

  if (cropY[1] >= 0 && cropY[1] < 1) {
    cropY[1] = 1;
  }

  const sourceOverlap = sourceContext.getImageData(cropX[0], cropY[0], cropX[1], cropY[1]).data;
  const targetOverlap = targetContext.getImageData(cropX[0], cropY[0], cropX[1], cropY[1]).data;
  let pixelOverlap = false;

  // Note: loops through every overlaping pixel in target
  for (let i = 0; i < (targetOverlap.length / 4); i++) {
    // Note: checks if the current pixel has an opacity greater than one on both source or target
    if (targetOverlap[i * 3] > 0 && sourceOverlap[i * 3] > 0) {
      pixelOverlap = true;
    }
  }

  // Note: if a pixel overlap was already found, target makes the function run faster
  if (!pixelOverlap) {
    // Note: loops through every overlaping pixel in source
    for (let i = 0; i < (sourceOverlap.length / 4); i++) {
      // Note: checks if the current pixel has an opacity greater than one on both source or target
      if (targetOverlap[i * 3] > 0 && sourceOverlap[i * 3] > 0) {
        pixelOverlap = true;
      }
    }
  }

  return pixelOverlap;
}

export default ({ data: sourceData }, { data: targetData }, canvasWidth, canvasHeight) => {
  const estimatedHorizontalOverlap = sourceData.x + sourceData.width >= targetData.x && sourceData.x <= targetData.x + targetData.width;
  const estimatedVerticalOverlap = sourceData.y + sourceData.height >= targetData.y && sourceData.y <= targetData.y + targetData.height;

  if (!estimatedHorizontalOverlap || !estimatedVerticalOverlap) {
    return Promise.resolve(false);
  }

  if (sourceData.width === 0 || sourceData.height === 0 || targetData.width === 0 || targetData.height === 0) {
    return Promise.resolve(false);
  }

  // return true;

  // Note: precisely calculations are performance expensive
  return calculatePrecisely(sourceData, targetData, canvasWidth, canvasHeight);
};
