import { EnemyType } from '@/enums';
import { getImagesOverlap } from '@/utils';
import Enemy from './enemy';

export default class Enemies {
  constructor(props) {
    const { context, width, height, state, player, background, onCrash, onScore } = props;

    this.context = context;
    this.canvasWidth = width;
    this.canvasHeight = height;
    this.state = state;
    this.background = background;
    this.player = player;
    this.onCrash = onCrash;
    this.onScore = onScore;
    this.records = [];

    this.create(props);
  }

  create(props) {
    this.state.level.enemies.forEach((enemy) => this.records.push(new Enemy({
      ...props,
      data: { ...enemy },
    })));
  }

  render({ state }) {
    const recordsToRemove = [];
    this.state = state;

    this.records.forEach((enemy) => {
      if (isNaN(enemy.data.x) || isNaN(enemy.data.y)) {
        return;
      }

      if (enemy.data.x + enemy.data.width < 0) {
        recordsToRemove.push(enemy);

        if (enemy.data.type !== EnemyType.BONUS) {
          this.onScore();
        }

        return;
      }

      enemy.render({ state });
    });

    recordsToRemove.forEach(({ data }) => this.records.splice(this.records.findIndex((enemy) => enemy.data.id === data.id), 1));

    if (!this.player.hasCollided) {
      this.checkCollision();
    }
  }

  async checkCollision() {
    if (!this.lastCollisionCheckTimestamp) {
      this.lastCollisionCheckTimestamp = Date.now();
    }

    const now = Date.now();
    if (now - this.lastCollisionCheckTimestamp < 50) {
      return;
    }

    this.lastCollisionCheckTimestamp = now;

    const { x: playerX, width: playerWidth } = this.player.airship;
    const closestEnemies = this.records.filter(({ data }) => data.x - data.width < playerX + playerWidth);

    for (let i = 0; i < closestEnemies.length; i++) {
      const enemy = closestEnemies[i];
      const { data } = enemy;
      const hadCollition = await getImagesOverlap(enemy, this.player.airship, this.canvasWidth, this.canvasHeight);

      if (hadCollition) {
        const method = data.type === EnemyType.BONUS ? 'onScore' : 'onCrash';
        this[method]({ type: data.type || EnemyType.DEFAULT });
        this.records.splice(this.records.findIndex((record) => record.data.id === data.id), 1);
      }
    }
  }
}
