<template>
  <audio ref="audio" preload="auto" loop>
    <source v-if="sound" :src="sound" :type="type">
  </audio>
</template>
<script setup>
import { computed, ref, watch } from 'vue';
import { store } from '@/components/ui-game/store';

const audio = ref(null);

const isPlaying = computed(() => store.state.isPlaying);
const sound = computed(() => store.state.isSoundEnabled && store.state.level?.sound);
const type = computed(() => store.state.level?.sound?.endsWith('.mp3') ? 'audio/mp3' : 'audio/mp4');

watch([isPlaying, sound], ([isPlaying, sound]) => {
  audio.value.pause();
  audio.value.currentTime = 0;

  if (isPlaying && sound) {
    audio.value.play().catch(() => {});
  }
});
</script>
