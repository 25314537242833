import { reactive } from 'vue';

const defaultStoreData = {
  data: undefined,
  level: 0,
  movement: {
    alternateMovements: 0,
    correctMovements: 0,
    allMovements: 0,
  },
  state: {
    bluetoothDeviceName: '',
    bonusValue: 1,
    footColorIntencity: 1,
    footOnlyMode: false,
    isEnded: false,
    isPlaying: false,
    isPaused: false,
    isSoundEnabled: true,
    killPenalty: 1,
    level: undefined,
    score: 0,
    showFootPrint: true,
  },
};

export const store = reactive({ ...defaultStoreData });

export const setStoreData = (value) => store.data = value;
export const setStoreLevel = (value) => store.level = value;
export const setStoreState = (value) => store.state = { ...store.state, ...value };
export const setStoreMovement = (value) => store.movement = { ...store.movement, ...value };
export const resetStoreMovement = () => setStoreMovement({
  alternateMovements: 0,
  correctMovements: 0,
  allMovements: 0,
});
export const resetStoreState = () => setStoreState({
  level: { ...store.data.levels[store.level] },
  isPlaying: true,
  isPaused: false,
  isEnded: false,
  score: 0,
});

export const getGameState = () => {
  const { data, movement, state } = store;
  const { astropaw_game_type, flowId, pageId, x_app_patient_id } = data;
  const { alternateMovements, correctMovements, allMovements } = movement;
  const { score, level } = state;

  return {
    requestId: Date.now(),
    flowId,
    pageId,
    data: {
      astropaw_points: score,
      astropaw_game_type,
      astropaw_current_level: level?.id || 0,
      astropaw_correct_movements: correctMovements,
      astropaw_all_movements: allMovements,
      astropaw_alternate_movements: alternateMovements,
      x_app_patient_id,
    },
  };
};
