<template>
  <div class="ui-game__info">
    <ui-game-info-foot v-if="isFootOnlyMode" />
    <ui-game-info-end v-else-if="store.state.isEnded" @replay="$emit(ActionType.REPLAY)" @next="$emit(ActionType.NEXT)" />
    <ui-game-info-pause v-else-if="store.state.isPaused" @resume="$emit(ActionType.RESUME)" />
    <ui-game-info-start v-else-if="!store.state.isPlaying" @start="$emit(ActionType.START)" />
  </div>
</template>
<script setup>
import { ActionType } from '@/enums';
import { store } from '@/components/ui-game/store';
import { getIsValueEqualsTrue } from '@/utils';
import UiGameInfoStart from './ui-game-info-start.vue';
import UiGameInfoPause from './ui-game-info-pause.vue';
import UiGameInfoEnd from './ui-game-info-end.vue';
import UiGameInfoFoot from './ui-game-info-foot.vue';

const isFootOnlyMode = getIsValueEqualsTrue(store.state.footOnlyMode);
</script>
