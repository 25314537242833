import { Game } from '@/enums';

export default class Sound {
  constructor({ $el, state }) {
    this.$el = $el.parentNode;
    this.state = state;
  }

  bonus() {
    this.play(`${Game.PREFIXES.sound}/bonus_pickup.mp3`);
  }

  engine() {
    this.play(`${Game.PREFIXES.sound}/engine_on.mp3`);
  }

  lost() {
    this.play(`${Game.PREFIXES.sound}/you_loose.mp3`);
  }

  play(src) {
    if (!this.state.isSoundEnabled) {
      return;
    }

    this.destroy();

    const audio = document.createElement('audio');
    audio.id = 'game-sound';
    audio.autoplay = true;
    audio.addEventListener('ended', () => this.destroy());

    const source = document.createElement('source');
    source.src = src;
    source.type = 'audio/mp3';

    audio.appendChild(source);
    this.$el.appendChild(audio);
  }

  destroy() {
    const audio = document.getElementById('game-sound');

    if (audio) {
      this.$el.removeChild(audio);
    }
  }
}
