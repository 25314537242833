<template>
  <div class="ui-game__info-foot">
    <img :src="startBackgroundSrc" class="ui-game__background" />
    <img :src="`${Game.PREFIXES.images}/shadow.png`" class="ui-game__shadow" />
    <div class="ui-game__info-foot-actions">
      <p v-if="isRegisterModeRef" @click.prevent="onSavePress" class="ui-game__info-foot-text" v-text="'Kliknij aby zapisać'"></p>
      <p @click.prevent="onRegisterPress" class="ui-game__info-foot-text" v-text="registerText"></p>
    </div>
    <ui-game-foot ref="footRef" :is-register-mode="isRegisterModeRef" class="ui-game__foot--only-mode" />
    <p v-if="bluetoothDeviceName" class="ui-game__bluetooth-device-name" v-text="bluetoothDeviceName"></p>
  </div>
</template>
<script setup>
import { computed, ref } from 'vue';
import UiGameFoot from '@/components/ui-game-foot/index.vue';
import { store, getGameState } from '@/components/ui-game/store';
import { Game } from '@/enums';

const footRef = ref(null);
const isRegisterModeRef = ref(false);

const isLandscape = computed(() => window.innerWidth > window.innerHeight);
const bluetoothDeviceName = store.state.bluetoothDeviceName;
const startBackgroundSrc = `${Game.PREFIXES.images}/${isLandscape ? 'start_background_horizontal' : 'start_background_vertical'}.jpg`;
const registerText = computed(() => isRegisterModeRef.value ? 'Zarejestruj ponownie' : 'Kliknij aby zarejestrować');

const onRegisterPress = () => {
  isRegisterModeRef.value = !isRegisterModeRef.value;
};

const onSavePress = () => {
  let footprint_data = [];

  try {
    footprint_data = [...footRef.value?.previousFootState]; 
  } catch {}

  const state = getGameState();
  const data = { ...state, data: { ...state.data, footprint_data }};

  window.Flutter?.postMessage('postFlow', data);

  if (store.data.restart_link) {
    setTimeout(() => {
      location.href = store.data.restart_link;
    }, 250);
  }
};
</script>
