import axios from 'axios';
import { Buffer } from 'buffer';

export default async (url) => {
  try {
    const image = await axios.get(url, { responseType: 'arraybuffer' });
    const raw = Buffer.from(image.data).toString('base64');
  
    return `data:${image.headers['content-type']};base64,${raw}`;
  } catch (error) {
    console.error(error);
  }
};
